"use client";

import { userSessionContext } from "@/contexts/user-context";

/**
 * A simple wrapper around fetch that throws an error if the response is not 200.
 * @param url The URL to fetch. Must be one of the ApiEndpoints enum.
 * @param body The request body to send. Optional.
 * @returns The JSON response.
 */
export async function fetcher<T = any>(
  url: string,
  body?: RequestInit
): Promise<T> {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      ...body,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
