export const QueryKeys = {
  Categorys: () => ["categories"],
  Customer: (...args: any[]) => ["custumer", ...args],
  User: (userId: string) => ["user", userId],
  Map: (...args: any[]) => ["map", ...args],
  Properties: (...args:any[]) => ["properties", ...args],
  Favorites: () => ["favorites"],
  RealEstate: (estateId: string) => ["realEstate", estateId],
  Orders: () => ["orders"],
};
