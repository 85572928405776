"use client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AppProgressBar } from "next-nprogress-bar";
import { type ReactNode } from "react";
import { TooltipProvider } from "../ui/tooltip";
import { ClusterProvider } from "./clusters-provider";
import ModalProvider from "../../contexts/modal-provider";
import { Toaster } from 'sonner';
import { ViewsPropertiesProvider } from "@/contexts/views-properties";
import { UserSessionProvider } from "@/contexts/user-context";
import Head from 'next/head';

type Props = {
	children: ReactNode;
};

export default function Provider({ children }: Props) {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 24 * (60 * 1000),
			},
		},
	});

	return (
		<QueryClientProvider client={queryClient}>
			<UserSessionProvider>
				<TooltipProvider>
					<ClusterProvider>
						<ModalProvider>
							<ViewsPropertiesProvider>
								<Head>
									<script
										async
										src="https://www.googletagmanager.com/gtag/js?id=G-5JFYEJG0EE"
									></script>
									<script>
										{`
											window.dataLayer = window.dataLayer || [];
											function gtag(){dataLayer.push(arguments);}
											gtag('js', new Date());
											gtag('config', 'G-5JFYEJG0EE');
										`}
									</script>
								</Head>
									{children}
								<AppProgressBar
									height="4px"
									color="#063B89"
									options={{ showSpinner: false }}
									shallowRouting
									/>
								<Toaster position="top-center" richColors />
							</ViewsPropertiesProvider>
						</ModalProvider>
					</ClusterProvider>
				</TooltipProvider>
			</UserSessionProvider>
			{/* <ReactQueryDevtools initialIsOpen={false} /> */}
		</QueryClientProvider>
	);
}
