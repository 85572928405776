import React, { createContext, useContext, useState, ReactNode } from "react";

type ModalProviderProps = {
  children: ReactNode;
};

type ModalData = {};

type ContextType = {
  data: ModalData;
  isOpen: boolean;
  setOpen: (modal: ReactNode, featchData?: () => Promise<any>) => void;
  setClose: () => void;
};

export const ModalContext = createContext<ContextType>({
  data: {},
  isOpen: false,
  setOpen: (modal: ReactNode, featchData?: () => Promise<any>) => {},
  setClose: () => {},
});

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<ModalData>({});
  const [showingModal, setShowingModal] = useState<any>();

  const setOpen = async (modal: ReactNode, featchData?: () => Promise<any>) => {
    if (modal) {
      if (featchData) {
        //@ts-ignore
        setData({ ...data, ...(await featchData()) } || {});
      }
      setShowingModal(modal); // Atualiza o modal para o estado
      setIsOpen(true);
    }
  };

  const setClose = () => {
    setIsOpen(false);
    setData({}); // Resetando os dados do modal
  };

  return (
    <ModalContext.Provider value={{ data, setOpen, setClose, isOpen }}>
      {children}
      {isOpen && <>{React.cloneElement(showingModal, { key: Date.now() })}</>}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within the modal provider");
  }
  return context;
};

export default ModalProvider;
